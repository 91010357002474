import { RouteRecordRaw } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import UserLayout from "@/layouts/UserLayout.vue";
import UserLoginView from "@/views/user/UserLoginView.vue";
import UserRegisterView from "@/views/user/UserRegisterView.vue";
import AdminView from "@/views/AdminView.vue";
import NoAuthView from "@/views/NoAuthView.vue";
import ACCESS_ENUM from "@/access/accessEnum";
import  AskQuestion  from "@/views/askai/AskQuestion.vue";
import AddInterface from "@/views/interface/AddInterface.vue";
import GetInterface from "@/views/interface/GetInterface.vue";
import UpdateInterface from "@/views/interface/UpdateInterface.vue";
import UpdateByUser from "@/views/interface/UpdateByUser.vue";
import BackendNotHealth from "@/views/BackendNotHealth.vue";


export const routes: Array<RouteRecordRaw> = [
  {
    path: "/user",
    name: "用户",
    component: UserLayout,
    children: [
      {
        path: "/user/login",
        name: "用户登录",
        component: UserLoginView,
      },
      {
        path: "/user/register",
        name: "用户注册",
        component: UserRegisterView,
      },
    ],
    meta: {
      hideInMenu: true,
    },
  },
  {
    path: "/",
    name: "主页",
    component: HomeView,
    meta: {
      access: ACCESS_ENUM.USER,
    },

  },
  {
    path: "/hide",
    name: "隐藏页面",
    component: HomeView,
    meta: {
      hideInMenu: true,
    },
  },
  {
    path: "/update/interface",
    name: "更新接口页面",
    component: UpdateInterface,
    meta: {
      hideInMenu: true,
    },
  },
  {
    path: "/noAuth",
    name: "无权限",
    component: NoAuthView,
    meta: {
      hideInMenu: true,   // 隐藏页面
    },
  },
  {
    path: "/noHealth",
    name: "后台未启动",
    component: BackendNotHealth,
    meta: {
      hideInMenu: true,   // 隐藏页面
    },
  },
  {
    path: "/askaiquestion",
    name: "问ai问题",
    component: AskQuestion,
  },
  {
    path: "/admin",
    name: "管理员可见",
    component: AdminView,
    meta: {
      access: ACCESS_ENUM.ADMIN,
    },
  },
  {
    path: "/add",
    name: "添加API",
    component: AddInterface,
  },
  {
    path: "/updatabyuser",
    name: "用户修改接口",
    component: UpdateByUser,
  },
  {
    path: "/getinterface",
    name: "获得API权限",
    component: GetInterface,
  },
  {
    path: "/about",
    name: "关于我的",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];
