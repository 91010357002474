
import { reactive } from 'vue';
import {
    InterfaceInfoControllerService
} from "../../../generated";
import message from "@arco-design/web-vue/es/message";

export default {
    setup() {
        const form = reactive({
            id: '',
            isRead: false,
        });
    
        const handleSubmit = async (interfaceId: any) => {
            const res = await InterfaceInfoControllerService.get100UsingGet(interfaceId);
            if (res.code === 0) {
                message.success("获取成功")
            } else {
                message.error("获取失败" + res.description);
            }
        };

        return {
            form,
            handleSubmit,
        };
    },
};
