// 假设使用axios作为HTTP客户端库
import axios from 'axios';
import { OpenAPI } from '../../generated/core/OpenAPI'

export class BackendCheckService {
  static async checkStatus() {
    if (await checkBackendStatus() === false){
      return false;
    }
    return true;
  }
}

let BASE = OpenAPI.BASE;
let name = 'Alice';
let greeting = `Hello, ${name}!`; // 结果为 "Hello, Alice!"

async function checkBackendStatus() {
  const backendCheckUrl = BASE + '/api/health/si'; // 替换为实际的后端健康检查API地址
  try {
    const response = await axios.post(backendCheckUrl);
    console.log(response.status);
    
    return response.status === 200; // 或其他表示成功的HTTP状态码
  } catch (error) {
    // 如果请求失败，说明后端服务可能未启动或不可达
    return false;
  }
}