import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, {
    model: $setup.form,
    style: { width: '600px' }
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, {
        field: "id",
        tooltip: "填想要开通的接口的id",
        label: "Username"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            modelValue: $setup.form.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.id) = $event)),
            placeholder: "填想要开通的接口的id"
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, { field: "isRead" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_checkbox, {
            modelValue: $setup.form.isRead,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.isRead) = $event))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" I have read the manual ")
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            "html-type": "submit",
            onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.handleSubmit(Number($setup.form.id))))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Submit")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model"]))
}