
  import { onMounted } from "vue";
  import { defineComponent, reactive,ref } from 'vue';
  import { useRouter, useRoute} from "vue-router";
  import message from "@arco-design/web-vue/es/message";
  import {
    InterfaceInfoControllerService
  } from "../../../generated";
  
  export default defineComponent({
    setup() {

        const router = useRouter();
        const route = useRoute();
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
        };
    
        const validateMessages = {
            required: '${label} is required!',
            types: {
            email: '${label} is not a valid email!',
            number: '${label} is not a valid number!',
            },
            number: {
            range: '${label} must be between ${min} and ${max}',
            },
        };
    
        const formState = ref({
            id: 0,
            name: '',
            method: '',
            url: '',
            requestParams: '',
            requestHeader: '',
            description: '',
            likeNum: 0,
        });
        /**
         * 根据题目 id 获取老的数据
         */
        const loadData = async () => {
            const id = route.query.id;
            if (!id) {
                return;
            }
            const res = await InterfaceInfoControllerService.getInterfaceInfoByIdUsingGet(Number(id));
            if (res.code === 0) {
                const data = res.data
                formState.value.name = String(data?.name)
                formState.value.id = Number(data?.id)
                formState.value.method = String(data?.method)
                formState.value.url = String(data?.url)
                formState.value.requestParams = String(data?.requestParams)
                formState.value.requestHeader = String(data?.requestHeader)
                formState.value.description = String(data?.description)
                formState.value.likeNum = Number(data?.likeNum)
                console.log(data?.likeNum);
                
            } else {
                message.error("查询失败");
            }
        };
        onMounted(() => {
            loadData();
        });
            
        const onFinish = async (values: any) => {
            const res = await InterfaceInfoControllerService.updateInterfaceInfoUsingPost(values);
            if (res.code === 0) {
                message.success('修改成功');
                router.push({
                    path: "/admin",
                });
            } else {
                message.error("修改失败");
            }
        };

        
        return {
            formState,
            onFinish,
            layout,
            validateMessages,
        };
    },
  });
  