import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, _mergeProps({ model: _ctx.formState }, _ctx.layout, {
    name: "nest-messages",
    "validate-messages": _ctx.validateMessages,
    onFinish: _ctx.onFinish
  }), {
    default: _withCtx(() => [
      _createVNode(_component_a_form_item, {
        name: ['name'],
        label: "接口名称",
        rules: [{ required: true }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            modelValue: _ctx.formState.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.name) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        name: ['method'],
        label: "请求方式",
        rules: [{ required: true }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            modelValue: _ctx.formState.method,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.method) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        name: ['url'],
        label: "请求url",
        rules: [{ required: true }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            modelValue: _ctx.formState.url,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.url) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        name: ['requestParams'],
        label: "requestParams",
        rules: [{ required: true }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            modelValue: _ctx.formState.requestParams,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.requestParams) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        name: ['requestHeader'],
        label: "requestHeader"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_input, {
            modelValue: _ctx.formState.requestHeader,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.requestHeader) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        name: ['description'],
        label: "详细描述"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_textarea, {
            modelValue: _ctx.formState.description,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.description) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_a_form_item, {
        "wrapper-col": { ..._ctx.layout.wrapperCol, offset: 8 }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_button, {
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onFinish(_ctx.formState))),
            type: "primary",
            "html-type": "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Submit")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["wrapper-col"])
    ]),
    _: 1
  }, 16, ["model", "validate-messages", "onFinish"]))
}