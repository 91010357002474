/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_BiResponse_ } from '../models/BaseResponse_BiResponse_';
import type { BaseResponse_string_ } from '../models/BaseResponse_string_';
import type { QuestionDTO } from '../models/QuestionDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AskAiControllerService {

    /**
     * askBi
     * @param formData 
     * @returns BaseResponse_BiResponse_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static askBiUsingPost(
formData?: {
/**
 * file
 */
file: Blob;
},
): CancelablePromise<BaseResponse_BiResponse_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ai/askbi',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * askQuestion
     * @param requestBody 
     * @returns BaseResponse_string_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static askQuestionUsingPost(
requestBody?: QuestionDTO,
): CancelablePromise<BaseResponse_string_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/ai/askquestion',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
