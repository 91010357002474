/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseResponse_boolean_ } from '../models/BaseResponse_boolean_';
import type { BaseResponse_int_ } from '../models/BaseResponse_int_';
import type { BaseResponse_List_User_ } from '../models/BaseResponse_List_User_';
import type { BaseResponse_long_ } from '../models/BaseResponse_long_';
import type { BaseResponse_User_ } from '../models/BaseResponse_User_';
import type { BaseResponse_UserAKSKVO_ } from '../models/BaseResponse_UserAKSKVO_';
import type { UserAKSKVO } from '../models/UserAKSKVO';
import type { UserLoginRequest } from '../models/UserLoginRequest';
import type { UserRegisterRequest } from '../models/UserRegisterRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserControllerService {

    /**
     * getCurrentUser
     * @returns BaseResponse_User_ OK
     * @throws ApiError
     */
    public static getCurrentUserUsingGet(): CancelablePromise<BaseResponse_User_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/current',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * deleteUser
     * @param requestBody 
     * @returns BaseResponse_boolean_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static deleteUserUsingPost(
requestBody?: number,
): CancelablePromise<BaseResponse_boolean_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/delete',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getAKSK
     * @returns BaseResponse_UserAKSKVO_ OK
     * @throws ApiError
     */
    public static getAkskUsingGet(): CancelablePromise<BaseResponse_UserAKSKVO_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/getAKSK',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * getSKByAK
     * @param accessKey accessKey
     * @returns UserAKSKVO OK
     * @throws ApiError
     */
    public static getSkByAkUsingGet(
accessKey: string,
): CancelablePromise<UserAKSKVO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/getSKByAK',
            query: {
                'accessKey': accessKey,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * userLogin
     * @param requestBody 
     * @returns BaseResponse_User_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static userLoginUsingPost(
requestBody?: UserLoginRequest,
): CancelablePromise<BaseResponse_User_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * userLogout
     * @returns BaseResponse_int_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static userLogoutUsingPost(): CancelablePromise<BaseResponse_int_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/logout',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * userRegister
     * @param requestBody 
     * @returns BaseResponse_long_ OK
     * @returns any Created
     * @throws ApiError
     */
    public static userRegisterUsingPost(
requestBody?: UserRegisterRequest,
): CancelablePromise<BaseResponse_long_ | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/user/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

    /**
     * searchUsers
     * @param username username
     * @returns BaseResponse_List_User_ OK
     * @throws ApiError
     */
    public static searchUsersUsingGet(
username?: string,
): CancelablePromise<BaseResponse_List_User_> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/user/search',
            query: {
                'username': username,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }

}
