/**
 * 权限定义
 */
const ACCESS_ENUM = {
  NOT_LOGIN: "notLogin",
  USER: "1",
  ADMIN: "2",
};

export default ACCESS_ENUM;
