
  
  import { defineComponent,ref } from 'vue';
  import message from "@arco-design/web-vue/es/message";
  import {
    InterfaceInfoControllerService
  } from "../../../generated";
  
  export default defineComponent({
    setup() {
      const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
      };
  
      const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        },
        number: {
          range: '${label} must be between ${min} and ${max}',
        },
      };
  
      const formState = ref({
        name: '',
        method: '',
        url: '',
        requestParams: '',
        requestHeader: '',
        description: '',
      });
      const onFinish = async (values: any) => {
        
        const res = await InterfaceInfoControllerService.addInterfaceInfoUsingPost(values);
        if (res.code === 0) {
            message.success('添加成功');
        } else {
            message.error("添加失败");
        }
      };
      return {
        formState,
        onFinish,
        layout,
        validateMessages,
      };
    },
  });
  